import { UserRoles } from "../redux/user/types";
import { PermissionLevels } from "../types/AccessControls";

export interface MenuDataImpl {
  title?: string;
  key?: string;
  url?: string;
  permissionLevel?: PermissionLevels;
  icon?: string;
  divider?: boolean;
  disabled?: boolean;
  target?: string;
  children?: MenuDataImpl[];
}

const { admin, contentReviewer, dataSpecialist, academicHead, videoEditor, videoManager } =
  UserRoles;

export default function getMenuData(): MenuDataImpl[] {
  return [
    {
      title: "General Management",
      key: "generalManagment",
      children: [
        {
          title: "Jobs",
          key: "job",
          url: "/jobs/",
          permissionLevel: PermissionLevels.JOBS,
          icon: "icmn icmn-briefcase",
        },
        {
          title: "Questions",
          key: "questions",
          permissionLevel: PermissionLevels.QUESTIONS,
          icon: "icmn icmn-books",
          children: [
            {
              title: "Question Collection",
              key: "questioncollection",
              url: "/questioncollections/",
              permissionLevel: PermissionLevels.QUESTION_COLLECTIONS,
            },
            {
              key: "appealedQuestions",
              title: "Appeals",
              url: "/questions/appeals/",
              permissionLevel: PermissionLevels.APPEALED_QUESTIONS,
            },
            {
              key: "expiredQuestions",
              title: "Expired",
              url: "/questions/expired/",
              permissionLevel: PermissionLevels.EXPIRED_QUESTIONS,
            },
            {
              key: "reportedQuestions",
              title: "Reported",
              url: "/questions/reported/",
              permissionLevel: PermissionLevels.REPORTED_QUESTIONS,
            },
            {
              key: "mapping",
              title: "Mapping",
              url: "/questions/mapping/",
              permissionLevel: PermissionLevels.QUESTIONS_MAPPING,
            },
          ],
        },
        {
          title: "Video Manager",
          key: "videoManager",
          icon: "fa fa-file-video-o",
          children: [
            {
              key: "hdVideos",
              title: "Converted Videos",
              url: "/videos/convertedVideos/",
              permissionLevel: PermissionLevels.VIDEOS,
            },
            {
              key: "qaVideos",
              title: "QA Videos",
              url: "/videos/qa/",
              permissionLevel: PermissionLevels.VIDEO_QA,
            },
          ],
        },
        {
          title: "Certificates",
          key: "certificates",
          icon: "fa fa-certificate",
          children: [
            {
              key: "createCertificateTemplate",
              title: "Create Template",
              url: "/certificates/createTemplate/",
              permissionLevel: PermissionLevels.ELEVATE
            }, {
              key: "generateCertificate",
              title: "Generate Certificate",
              url: "/certificates/generateCertificate/",
              permissionLevel: PermissionLevels.ELEVATE
            }
          ]
        },
        {
          title: "Discuss",
          key: "discuss",
          url: "/discuss/",
          icon: "fa fa-comments",
          permissionLevel: PermissionLevels.DISCUSS,
        },
        {
          title: "Study Card Packs",
          key: "studyCardPacks",
          url: "/studycards/packs/",
          icon: "icmn icmn-stack",
          permissionLevel: PermissionLevels.STUDY_CARD_PACKS,
        },
        {
          title: "Teachers",
          key: "teachers",
          url: "/teachers/",
          permissionLevel: PermissionLevels.TEACHERS,
          icon: "icmn icmn-user-tie",
        },
        {
          title: "Profiles",
          key: "profiles",
          url: "/profiles/",
          permissionLevel: PermissionLevels.PROFILE,
          icon: "icmn icmn-profile",
        },
        {
          key: "batches",
          title: "Batches",
          url: "/batches/",
          permissionLevel: PermissionLevels.BATCH,
          icon: "icmn icmn-stack",
        },
        {
          key: 'remuneration',
          title: 'Remuneration',
          url: '/remunerations/live-classes',
          icon: 'fa fa-calculator',
          permissionLevel: PermissionLevels.LIVECLASS_REMUNERATION,
        },
        {
          key: 'validityExtension',
          title: 'Validity Extension',
          url: '/validity-extension',
          icon: 'fa fa-calendar',
          permissionLevel: PermissionLevels.VALIDITY_EXTENSION,
        },
      ],
    },
    {
      title: "Test Prep",
      key: "testPrep",
      children: [
        {
          key: "testprepTrackCategory",
          title: "Track Category",
          url: "/trackcategory/testprep/",
          icon: "fa fa-bars",
          permissionLevel: PermissionLevels.TRACKS,
        },
        {
          title: "Tracks",
          key: "testprepTracks",
          url: "/tracks/testprep/",
          icon: "fa fa-list",
          permissionLevel: PermissionLevels.TRACKS,
        },
        {
          key: "testPrepVideo",
          title: "Videos",
          icon: "fa fa-video-camera",
          children: [
            {
              key: "videopacks",
              title: "Packs",
              url: "/videos/packs/",
              permissionLevel: PermissionLevels.PACKS,
            },
            {
              key: "packSorting",
              title: "Pack Sorting",
              url: "/videos/packs/sorting/",
              permissionLevel: PermissionLevels.PACKS,
            },
          ],
        },
        {
          title: "Exams",
          key: "exams",
          url: "/exams/testseriespacks/",
          icon: "icmn icmn-paste",
          permissionLevel: PermissionLevels.PACKS,
        },
        {
          key: "testPrepGold",
          title: "Entri Gold",
          icon: "fa fa-star",
          children: [
            {
              title: "Study Plans",
              key: "studyPlans",
              permissionLevel: PermissionLevels.DAILYSTUDYPLAN,
              children: [
                {
                  title: "Daily Study Plan",
                  key: "dailystudyPlans",
                  url: "/daily-study-plans/testprep",
                  permissionLevel: PermissionLevels.DAILYSTUDYPLAN,
                },
                {
                  title: "Free Study Plan",
                  key: "freePlans",
                  url: "/free-study-plans/testprep",
                  permissionLevel: PermissionLevels.FREETRIALSTUDYPLAN,
                },
              ],
            },
            {
              title: "Live Classes",
              key: "liveClassesTestPrep",
              url: "/live-classes/testprep",
              permissionLevel: PermissionLevels.DAILYSTUDYPLAN,
            },
            {
              title: "Gold Purchases",
              key: "goldPurchses",
              url: "/gold-purchases/testprep",
              permissionLevel: PermissionLevels.PURCHASE,
            },
            {
              title: "Onboarding Questions",
              key: "onboardingQuestions",
              url: "/onboarding-questions",
              permissionLevel: PermissionLevels.ONBOARDINGQUESTION,
            },
            {
              title: "Gold Programs",
              key: "goldPrograms",
              url: "/gold-Programs/testprep",
              permissionLevel: PermissionLevels.GOLDPROGRAM,
            },
          ],
        },
      ],
    },

    {
      title: "Skilling",
      key: "skilling",
      children: [
        {
          key: "skillingTrackCategory",
          title: "Track Category",
          url: "/trackcategory/skilling/",
          icon: "fa fa-bars",
          permissionLevel: PermissionLevels.TRACKS,
        },
        {
          title: "Tracks",
          key: "skillingTracks",
          url: "/tracks/skilling/",
          icon: "fa fa-list",
          permissionLevel: PermissionLevels.TRACKS,
        },
        {
          title: "Courses",
          key: "skillingpacks",
          url: "/packs/",
          icon: "fa fa-crosshairs",
          permissionLevel: PermissionLevels.TRACKS,
        },
        {
          key: "skillingGold",
          title: "Entri Gold",
          icon: "fa fa-star",
          children: [
            {
              title: "Study Plans",
              key: "skillingstudyPlans",
              permissionLevel: PermissionLevels.DAILYSTUDYPLAN,
              children: [
                {
                  title: "Daily Study Plan",
                  key: "skillingdailystudyPlans",
                  url: "/daily-study-plans/skilling",
                  permissionLevel: PermissionLevels.DAILYSTUDYPLAN,
                },
                {
                  title: "Free Study Plan",
                  key: "skillingfreePlans",
                  url: "/free-study-plans/skilling",
                  permissionLevel: PermissionLevels.FREETRIALSTUDYPLAN,
                },
              ],
            },
            {
              title: "Live Classes",
              key: "liveClassesSkilling",
              url: "/live-classes/skilling",
              permissionLevel: PermissionLevels.DAILYSTUDYPLAN,
            },
            {
              title: "Gold Purchases",
              key: "skillinggoldPurchses",
              url: "/gold-purchases/skilling",
              permissionLevel: PermissionLevels.PURCHASE,
            },
            {
              title: "Onboarding Questions",
              key: "skillingonboardingQuestions",
              url: "/onboarding-questions",
              permissionLevel: PermissionLevels.ONBOARDINGQUESTION,
            },
            {
              title: "Gold Programs",
              key: "skillinggoldPrograms",
              url: "/gold-Programs/skilling",
              permissionLevel: PermissionLevels.GOLDPROGRAM,
            },
          ],
        },
      ],
    },
    {
      title: "Elevate",
      key: "entriElevate",
      children: [
        {
          key: "elevateCourses",
          title: "Courses",
          url: "/elevate/courses/",
          icon: "fa fa-university ",
          permissionLevel: PermissionLevels.ELEVATE,
        },
        {
          key: "elevateLiveClasses",
          title: "Live Classes",
          url: "/live-classes/elevate",
          icon: "fa fa-video-camera",
          permissionLevel: PermissionLevels.ELEVATE_LIVE_CLASS,
        },
        {
          key: "elevateBatches",
          title: "Batches",
          url: "/elevate/batches",
          icon: "icmn-clipboard",
          permissionLevel: PermissionLevels.ELEVATE_LIVE_CLASS,
        },
        {
          key: "elevatePurchaess",
          title: "Purchases",
          url: "/elevate/purchses",
          icon: "icmn-cart",
          permissionLevel: PermissionLevels.ELEVATE_PURCHASE,
        },
      ],
    },
    {
      title: "Growth Tools",
      key: "growthTools",
      children: [
        {
          key: "productPackages",
          title: "Product Packages",
          url: "/pricing/productpackages/",
          icon: "icmn icmn-database",
          permissionLevel: PermissionLevels.PRODUCTPACKAGE,
        },
        {
          title: "Landing Pages",
          key: "landingPages",
          icon: "icmn icmn-newspaper",
          permissionLevel: PermissionLevels.LANDING_PAGE,
          children: [
            {
              title: "Manage Pages",
              key: "landingPageCMS",
              url: "/cms/",
              permissionLevel: PermissionLevels.LANDING_PAGE,
            },
            {
              title: "Global Components",
              key: "globalComponents",
              url: "/categories/",
              permissionLevel: PermissionLevels.LANDING_PAGE,
            },
          ],
        },
      ],
    },
  ];
}
