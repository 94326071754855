import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { RouterProps } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'
import Loader from './components/LayoutComponents/Loader'
import IndexLayout from './layouts'
import NotFoundPage from './pages/404'
import UnAuthorizedPage from './pages/403'
import { BASE_MODES, QUESTION_MODES, QUESTION_TYPES } from './helper/constants'
import AuthRoute from './components/LayoutComponents/AuthRoute'
import RedirectRootPath from './components/LayoutComponents/RedirectRootPath'
import { PermissionLevels, Permissions } from './types/AccessControls'

const { create, edit } = BASE_MODES
const { translate } = QUESTION_MODES
const { compounded, normal } = QUESTION_TYPES

export interface Routes {
  component: React.ComponentType<any>
  path: string
  exact: boolean
  requiredPermission?: [PermissionLevels, Permissions]
}

const routes: Routes[] = [
  // redirect root page according to the user role
  {
    path: '/',
    component: RedirectRootPath,
    exact: true,
  },
  // System Pages
  {
    path: '/user/login/',
    component: lazy(() => import('./pages/user/login')),
    exact: true,
  },
  {
    path: '/user/forgot/',
    component: lazy(() => import('./pages/user/forgot')),
    exact: true,
  },

  // Dashboards
  {
    path: '/dashboard/',
    component: lazy(() => import('./pages/dashboard/admin')),
    exact: true,
  },

  /**
   * /////////////////////////////////////////////////////
   * ************************ Job ************************
   * /////////////////////////////////////////////////////
   */
  {
    path: '/jobs/',
    component: lazy(() => import('./pages/jobs/JobList')),
    exact: true,
    requiredPermission: [PermissionLevels.JOBS, Permissions.LIST],
  },
  {
    path: '/jobs/:jobId(\\d+)/',
    component: lazy(() => import('./pages/jobs/JobDetail')),
    exact: true,
    requiredPermission: [PermissionLevels.JOBS, Permissions.LIST],
  },
  {
    path: `/jobs/:jobId(\\d+|new)/:mode(${create}|${edit})/`,
    component: lazy(() => import('./pages/jobs/JobInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.JOBS, Permissions.CREATE],
  },
  // Job Data Specialist
  {
    path: '/jobs/:jobId(\\d+)/questioncollections/:collectionId/questions/',
    component: lazy(() => import('./pages/questions/dataSpecialist/QuestionList')),
    exact: true,
    requiredPermission: [PermissionLevels.QUESTIONS, Permissions.LIST],
  },
  {
    path: '/jobs/:jobId(\\d+)/questioncollections/:collectionId/questions/:questionId(\\d+|new)/',
    component: lazy(() => import('./pages/questions/dataSpecialist/QuestionDetails')),
    exact: true,
    requiredPermission: [PermissionLevels.QUESTIONS, Permissions.LIST],
  },
  {
    path: `/jobs/:jobId(\\d+)/questioncollections/:collectionId/questions/:questionId(\\d+|new)/:mode(${create}|${edit}|${translate})/:questionType(${normal}|${compounded})/:lang?/`,
    component: lazy(() => import('./pages/questions/QuestionInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.QUESTIONS, Permissions.CREATE],
  },
  // Job Content Reviewer
  {
    path: '/jobs/:jobId(\\d+)/questioncollections/:collectionId/review/',
    component: lazy(() => import('./pages/questions/qa/QAListing')),
    exact: true,
    requiredPermission: [PermissionLevels.QUESTIONS, Permissions.LIST],
  },
  {
    path: '/jobs/:jobId(\\d+)/questioncollections/:collectionId/review/question/',
    component: lazy(() => import('./pages/questions/qa/QAReview')),
    exact: true,
    requiredPermission: [PermissionLevels.QUESTIONS, Permissions.LIST],
  },
  {
    path: '/jobs/:jobId(\\d+)/questioncollections/:collectionId/review/final/',
    component: lazy(() => import('./pages/questions/academicHead/QuestionList')),
    exact: true,
    requiredPermission: [PermissionLevels.QUESTIONS, Permissions.LIST],
  },
  // Video QA Jobs
  {
    path: '/jobs/:jobId/childjob/:childJobId/videoqa/:videoId',
    component: lazy(() => import('./pages/videos/qa/VideoQAInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.VIDEO_QA, Permissions.LIST],
  },

  /**
   * /////////////////////////////////////////////////////
   * ************* Admin Question Collection *************
   * /////////////////////////////////////////////////////
   */
  {
    path: '/questioncollections/',
    component: lazy(() => import('./pages/collection/questions/List')),
    exact: true,
    requiredPermission: [PermissionLevels.QUESTION_COLLECTIONS, Permissions.LIST],
  },
  {
    path: '/questioncollections/:collectionId/print',
    component: lazy(() => import('./pages/questions/printQuestions/PrintQuestions')),
    exact: true,
    requiredPermission: [PermissionLevels.QUESTION_COLLECTIONS, Permissions.LIST],
  },
  {
    path: '/questioncollections/create/',
    component: lazy(() => import('./pages/collection/questions/Create')),
    exact: true,

    requiredPermission: [PermissionLevels.QUESTION_COLLECTIONS, Permissions.CREATE],
  },
  {
    path: '/questioncollections/:collectionId/edit/',
    component: lazy(() => import('./pages/collection/questions/Edit')),
    exact: true,

    requiredPermission: [PermissionLevels.QUESTION_COLLECTIONS, Permissions.EDIT],
  },
  {
    path: '/questioncollections/:collectionId/questions/',
    component: lazy(() => import('./pages/questions/QuestionList')),
    exact: true,

    requiredPermission: [PermissionLevels.QUESTIONS, Permissions.LIST],
  },
  {
    path: '/questioncollections/:collectionId/questions/:questionId/',
    component: lazy(() => import('./pages/questions/QuestionDetail')),
    exact: true,

    requiredPermission: [PermissionLevels.QUESTIONS, Permissions.LIST],
  },
  {
    path: `/questioncollections/:collectionId/questions/:questionId(\\d+|new)/:mode(${create}|${edit}|${translate})/:questionType(${normal}|${compounded})/:lang?/`,
    component: lazy(() => import('./pages/questions/QuestionInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.QUESTIONS, Permissions.CREATE],
  },
  {
    path: '/mock/create/',
    component: lazy(() => import('./pages/mock/mockCreate')),
    exact: true,
    requiredPermission: [PermissionLevels.MOCK, Permissions.CREATE],
  },
  /**
   * /////////////////////////////////////////////////////
   * ********************* Question **********************
   * /////////////////////////////////////////////////////
   */
  {
    path: '/questions/appeals/',
    component: lazy(() => import('./pages/questions/appeals/AppealList')),
    exact: true,
    requiredPermission: [PermissionLevels.APPEALED_QUESTIONS, Permissions.LIST],
  },
  {
    path: '/questions/appeals/:questionId/:lang/',
    component: lazy(() => import('./pages/questions/appeals/AppealDetails')),
    exact: true,
    requiredPermission: [PermissionLevels.APPEALED_QUESTIONS, Permissions.LIST],
  },
  {
    path: '/questions/expired/',
    component: lazy(() => import('./pages/questions/expired/ExpiredList')),
    exact: true,
    requiredPermission: [PermissionLevels.EXPIRED_QUESTIONS, Permissions.LIST],
  },
  {
    path: '/questions/:questionId(\\d+)/',
    component: lazy(() => import('./pages/questions/QuestionDetail')),
    exact: true,
    requiredPermission: [PermissionLevels.QUESTIONS, Permissions.LIST],
  },
  {
    path: `/questions/:questionId(\\d+|new)/:mode(${create}|${edit}|${translate})/:questionType(${normal}|${compounded})/:lang?/`,
    component: lazy(() => import('./pages/questions/QuestionInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.QUESTIONS, Permissions.CREATE],
  },
  /**
   * /////////////////////////////////////////////////////
   * ************** admin study cards routes **************
   * /////////////////////////////////////////////////////
   */
  /* Pack */
  {
    path: '/studycards/packs/',
    component: lazy(() => import('./pages/studycards/packs/PackList')),
    exact: true,
    requiredPermission: [PermissionLevels.STUDY_CARD_PACKS, Permissions.LIST],
  },
  {
    path: '/studycards/packs/create/',
    component: lazy(() => import('./pages/studycards/packs/PackCreate')),
    exact: true,
    requiredPermission: [PermissionLevels.STUDY_CARD_PACKS, Permissions.CREATE],
  },
  {
    path: '/studycards/packs/:packId/edit/',
    component: lazy(() => import('./pages/studycards/packs/PackEdit')),
    exact: true,
    requiredPermission: [PermissionLevels.STUDY_CARD_PACKS, Permissions.EDIT],
  },
  /* stack */
  {
    path: '/studycards/packs/:packId/stacks/',
    component: lazy(() => import('./pages/studycards/stacks/StackList')),
    exact: true,
    requiredPermission: [PermissionLevels.STUDY_CARD_STACKS, Permissions.LIST],
  },
  {
    path: '/studycards/packs/:packId/stacks/create/',
    component: lazy(() => import('./pages/studycards/stacks/StackCreate')),
    exact: true,
    requiredPermission: [PermissionLevels.STUDY_CARD_STACKS, Permissions.CREATE],
  },
  {
    path: '/studycards/packs/:packId/stacks/:stackId/edit/',
    component: lazy(() => import('./pages/studycards/stacks/StackEdit')),
    exact: true,
    requiredPermission: [PermissionLevels.STUDY_CARD_STACKS, Permissions.EDIT],
  },
  {
    path: '/studycards/packs/:packId/stacks/quizzes/create/',
    component: lazy(() => import('./pages/exams/quiz/QuizInterface')),
    exact: true,
  },
  {
    path: '/studycards/packs/:packId/stacks/quizzes/:quizId/edit/',
    component: lazy(() => import('./pages/exams/quiz/QuizInterface')),
    exact: true,
  },
  /* card */
  {
    path: '/studycards/packs/:packId/stacks/:stackId/cards/',
    component: lazy(() => import('./pages/studycards/cards/CardList')),
    exact: true,
    requiredPermission: [PermissionLevels.STUDY_CARD_CARDS, Permissions.LIST],
  },
  {
    path: '/studycards/packs/:packId/stacks/:stackId/cards/create/',
    component: lazy(() => import('./pages/studycards/cards/CardCreate')),
    exact: true,
    requiredPermission: [PermissionLevels.STUDY_CARD_CARDS, Permissions.CREATE],
  },
  {
    path: '/studycards/packs/:packId/stacks/:stackId/cards/:cardId/:mode/',
    component: lazy(() => import('./pages/studycards/cards/CardEdit')),
    exact: true,
    requiredPermission: [PermissionLevels.STUDY_CARD_CARDS, Permissions.EDIT],
  },
  /**
   * /////////////////////////////////////////////////////
   * ********************* Discuss **********************
   * /////////////////////////////////////////////////////
   */
  {
    path: '/discuss/',
    component: lazy(() => import('./pages/discuss/List')),
    exact: true,
    requiredPermission: [PermissionLevels.DISCUSS, Permissions.LIST],
  },
  {
    path: '/discuss/:postId/',
    component: lazy(() => import('./pages/discuss/PostDetails')),
    exact: true,
    requiredPermission: [PermissionLevels.DISCUSS, Permissions.LIST],
  },
  /**
   * /////////////////////////////////////////////////////
   * ********************* Teachers **********************
   * /////////////////////////////////////////////////////
   */
  {
    path: '/teachers/',
    component: lazy(() => import('./pages/teachers/TeachersList')),
    exact: true,
    requiredPermission: [PermissionLevels.TEACHERS, Permissions.LIST],
  },
  {
    path: `/teachers/:teacherId(\\d+|new)/:teacherMode(${create}|${edit})/`,
    component: lazy(() => import('./pages/teachers/TeacherInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.TEACHERS, Permissions.CREATE],
  },
  /**
   * /////////////////////////////////////////////////////
   * ********************* Videos **********************
   * /////////////////////////////////////////////////////
   */
  {
    path: '/videos/packs/',
    component: lazy(() => import('./pages/videos/videoPacks/VideoPackList')),
    exact: true,
    requiredPermission: [PermissionLevels.PACKS, Permissions.LIST],
  },
  {
    path: '/videos/packs/add/',
    component: lazy(() => import('./pages/videos/videoPacks/VideoPackInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.PACKS, Permissions.CREATE],
  },
  {
    path: '/videos/packs/sorting/',
    component: lazy(() => import('./pages/videos/videoPacks/VideoPackSorting')),
    exact: true,
    requiredPermission: [PermissionLevels.PACKS, Permissions.CREATE],
  },
  {
    path: '/videos/packs/:packId/edit',
    component: lazy(() => import('./pages/videos/videoPacks/VideoPackInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.PACKS, Permissions.EDIT],
  },
  {
    path: '/videos/packs/:packId/playlist/',
    component: lazy(() => import('./pages/videos/playlist/PlaylistListing')),
    exact: true,
    requiredPermission: [PermissionLevels.PLAYLIST, Permissions.LIST],
  },
  {
    path: '/videos/packs/:packId/playlist/add/',
    component: lazy(() => import('./pages/videos/playlist/PlaylistInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.PLAYLIST, Permissions.CREATE],
  },
  {
    path: '/videos/packs/:packId/playlist/:playlistId/edit/',
    component: lazy(() => import('./pages/videos/playlist/PlaylistInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.PLAYLIST, Permissions.EDIT],
  },
  {
    path: '/videos/packs/:packId/playlist/quizzes/add/',
    component: lazy(() => import('./pages/exams/quiz/QuizInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.VIDEOS, Permissions.LIST],
  },
  {
    path: '/videos/packs/:packId/playlist/quizzes/:quizId/edit/',
    component: lazy(() => import('./pages/exams/quiz/QuizInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.VIDEOS, Permissions.LIST],
  },
  {
    path: '/videos/packs/:packId/playlist/:playlistId/videolist/',
    component: lazy(() => import('./pages/videos/video/VideoList')),
    exact: true,
    requiredPermission: [PermissionLevels.VIDEOS, Permissions.LIST],
  },
  {
    path: '/videos/packs/:packId/playlist/:playlistId/videolist/add/',
    component: lazy(() => import('./pages/videos/video/VideoInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.VIDEOS, Permissions.CREATE],
  },
  {
    path: '/videos/packs/:packId/playlist/:playlistId/videolist/:videoId/edit/',
    component: lazy(() => import('./pages/videos/video/VideoInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.VIDEOS, Permissions.EDIT],
  },
  {
    path: '/videos/packs/:packId/playlist/:playlistId/videolist/quizzes/add/',
    component: lazy(() => import('./pages/exams/quiz/QuizInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.VIDEOS, Permissions.CREATE],
  },
  {
    path: '/videos/packs/:packId/playlist/:playlistId/videolist/quizzes/:quizId/edit/',
    component: lazy(() => import('./pages/exams/quiz/QuizInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.VIDEOS, Permissions.EDIT],
  },
  {
    path: '/videos/packs/:packId/playlist/:playlistId/videolist/:videoId/',
    component: lazy(() => import('./pages/videos/video/VideoDetails')),
    exact: true,
    requiredPermission: [PermissionLevels.VIDEOS, Permissions.LIST],
  },
  {
    path: '/videos/convertedVideos/',
    component: lazy(() => import('./pages/videos/hdVideos/HDVideoList')),
    exact: true,
    requiredPermission: [PermissionLevels.VIDEOS, Permissions.LIST],
  },
  {
    path: '/videos/convertedVideos/add',
    component: lazy(() => import('./pages/videos/hdVideos/HdVideoInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.VIDEOS, Permissions.LIST],
  },
  {
    path: '/videos/convertedVideos/:HDVideoId/edit/',
    component: lazy(() => import('./pages/videos/hdVideos/HdVideoInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.VIDEOS, Permissions.LIST],
  },
  {
    path: '/videos/qa/',
    component: lazy(() => import('./pages/videos/qa/QAVideos')),
    exact: true,
    requiredPermission: [PermissionLevels.VIDEO_QA, Permissions.LIST],
  },
  /**
   * /////////////////////////////////////////////////////
   * ********************* Exams **********************
   * /////////////////////////////////////////////////////
   */
  {
    path: '/exams/testseriespacks/',
    component: lazy(() => import('./pages/exams/testSeriesPack/TestSeriesPacksList')),
    exact: true,
    requiredPermission: [PermissionLevels.PACKS, Permissions.LIST],
  },
  {
    path: '/exams/testseriespacks/add/',
    component: lazy(() => import('./pages/exams/testSeriesPack/TestSeriesPackInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.PACKS, Permissions.CREATE],
  },
  {
    path: '/exams/testseriespacks/:packId/edit/',
    component: lazy(() => import('./pages/exams/testSeriesPack/TestSeriesPackInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.PACKS, Permissions.EDIT],
  },
  {
    path: '/exams/testseriespacks/:packId/testseries/',
    component: lazy(() => import('./pages/exams/testSeries/TestSeriesList')),
    exact: true,
    requiredPermission: [PermissionLevels.TEST_SERIES, Permissions.LIST],
  },
  {
    path: '/exams/testseriespacks/:packId/testseries/add/',
    component: lazy(() => import('./pages/exams/testSeries/TestSeriesInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.TEST_SERIES, Permissions.CREATE],
  },
  {
    path: '/exams/testseriespacks/:packId/testseries/:testSeriesId/edit/',
    component: lazy(() => import('./pages/exams/testSeries/TestSeriesInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.TEST_SERIES, Permissions.EDIT],
  },
  {
    path: '/exams/testseriespacks/:packId/testseries/:testSeriesId/quizzes/',
    component: lazy(() => import('./pages/exams/quiz/QuizList')),
    exact: true,
    requiredPermission: [PermissionLevels.QUIZ, Permissions.LIST],
  },
  {
    path: '/exams/testseriespacks/:packId/testseries/:testSeriesId/quizzes/add/',
    component: lazy(() => import('./pages/exams/quiz/QuizInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.QUIZ, Permissions.CREATE],
  },
  {
    path: '/exams/testseriespacks/:packId/testseries/:testSeriesId/quizzes/:quizId/edit/',
    component: lazy(() => import('./pages/exams/quiz/QuizInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.QUIZ, Permissions.EDIT],
  },
  {
    path: '/exams/testseriespacks/:packId/testseries/:testSeriesId/quizzes/:quizId/questions/',
    component: lazy(() => import('./pages/exams/quiz/QuestionList')),
    exact: true,
    requiredPermission: [PermissionLevels.QUESTIONS, Permissions.LIST],
  },

  /**
   * /////////////////////////////////////////////////////
   * ********************* Product Elements **********************
   * /////////////////////////////////////////////////////
   */

  {
    path: '/trackcategory/:vertical(skilling|testprep)/',
    component: lazy(() => import('./pages/productElements/TrackCategory/List')),
    exact: true,
    requiredPermission: [PermissionLevels.TRACKS, Permissions.LIST],
  },
  {
    path: `/trackcategory/:vertical(skilling|testprep)/:trackCategoryId(\\d+|new)/:mode(${create}|${edit})/`,
    component: lazy(() => import('./pages/productElements/TrackCategory/TrackCategoryInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.TRACKS, Permissions.CREATE],
  },
  {
    path: '/trackcategory/:vertical(skilling|testprep)/sorting/:mode(trackcategory|track)',
    component: lazy(() => import('./pages/productElements/SortingInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.TRACKS, Permissions.LIST],
  },
  // Tracks
  {
    path: '/trackcategory/:vertical(skilling|testprep)/:categoryId(\\d+)/tracks',
    component: lazy(() => import('./pages/productElements/tracks/List')),
    exact: true,
    requiredPermission: [PermissionLevels.TRACKS, Permissions.LIST],
  },
  {
    path: `/trackcategory/:vertical(skilling|testprep)/:categoryId(\\d+)/tracks/:trackId(\\d+|new)/:trackMode(${create}|${edit})/`,
    component: lazy(() => import('./pages/productElements/tracks/TrackInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.TRACKS, Permissions.CREATE],
  },

  {
    path: '/trackcategory/:vertical(skilling|testprep)/:categoryId(\\d+)/tracks/sorting/:mode(trackcategory|track)',
    component: lazy(() => import('./pages/productElements/SortingInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.TRACKS, Permissions.LIST],
  },
  // Platforms
  {
    path: `/trackcategory/:vertical(skilling|testprep)/:categoryId(\\d+)/tracks/:trackId(\\d+)/platforms/`,
    component: lazy(() => import('./pages/productElements/platform/List')),
    exact: true,
    requiredPermission: [PermissionLevels.PLATFORM, Permissions.LIST],
  },
  {
    path: `/trackcategory/:vertical(skilling|testprep)/:categoryId(\\d+)/tracks/:trackId(\\d+)/platforms/:platformId(\\d+|new)/:platformMode(${create}|${edit})/`,
    component: lazy(() => import('./pages/productElements/platform/PlatformInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.PLATFORM, Permissions.CREATE],
  },
  {
    path: `/trackcategory/:vertical(skilling|testprep)/:categoryId(\\d+)/tracks/:trackId(\\d+)/platforms/:platformId(\\d+)/`,
    component: lazy(() => import('./pages/productElements/platform/PlatformDetails')),
    exact: true,
    requiredPermission: [PermissionLevels.PLATFORM, Permissions.LIST],
  },
  // Screenlet
  {
    path: '/trackcategory/:vertical(skilling|testprep)/:categoryId(\\d+)/tracks/:trackId/screenlets/',
    component: lazy(() => import('./pages/screenlets/ScreenletList')),
    exact: true,
    requiredPermission: [PermissionLevels.SCREENLET, Permissions.LIST],
  },
  {
    path: '/trackcategory/:vertical(skilling|testprep)/:categoryId(\\d+)/tracks/:trackId/screenlets/:screenletId/',
    component: lazy(() => import('./pages/screenlets/ScreenletMappedResources')),
    exact: true,
    requiredPermission: [PermissionLevels.SCREENLET, Permissions.LIST],
  },
  // Subjects
  {
    path: `/trackcategory/:vertical(skilling|testprep)/:categoryId(\\d+)/tracks/:trackId(\\d+)/platforms/:platformId(\\d+)/subjects/`,
    component: lazy(() => import('./pages/productElements/subject/List')),
    exact: true,
    requiredPermission: [PermissionLevels.SUBJECT, Permissions.LIST],
  },
  {
    path: `/trackcategory/:vertical(skilling|testprep)/:categoryId(\\d+)/tracks/:trackId(\\d+)/platforms/:platformId(\\d+)/subjects/:subjectId(\\d+|new)/:subjectMode(${create}|${edit})/`,
    component: lazy(() => import('./pages/productElements/subject/SubjectInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.SUBJECT, Permissions.CREATE],
  },
  //Modules
  {
    path: `/trackcategory/:vertical(skilling|testprep)/:categoryId(\\d+)/tracks/:trackId(\\d+)/platforms/:platformId(\\d+)/subjects/:subjectId(\\d+)/`,
    component: lazy(() => import('./pages/productElements/module/List')),
    exact: true,
    requiredPermission: [PermissionLevels.MODULE, Permissions.LIST],
  },
  {
    path: `/trackcategory/:vertical(skilling|testprep)/:categoryId(\\d+)/tracks/:trackId(\\d+)/platforms/:platformId(\\d+)/subjects/:subjectId(\\d+)/modules/:moduleId(\\d+|new)/:moduleMode(${create}|${edit})/`,
    component: lazy(() => import('./pages/productElements/module/ModuleInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.MODULE, Permissions.CREATE],
  },
  //Topics
  {
    path: `/trackcategory/:vertical(skilling|testprep)/:categoryId(\\d+)/tracks/:trackId(\\d+)/platforms/:platformId(\\d+)/subjects/:subjectId(\\d+)/modules/:moduleId(\\d+)/`,
    component: lazy(() => import('./pages/productElements/topic/List')),
    exact: true,
    requiredPermission: [PermissionLevels.TOPIC, Permissions.LIST],
  },
  {
    path: `/trackcategory/:vertical(skilling|testprep)/:categoryId(\\d+)/tracks/:trackId(\\d+)/platforms/:platformId(\\d+)/subjects/:subjectId(\\d+)/modules/:moduleId(\\d+)/topics/:topicId(\\d+|new)/:topicMode(${create}|${edit})/`,
    component: lazy(() => import('./pages/productElements/topic/TopicInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.TOPIC, Permissions.CREATE],
  },

  /**`
   * /////////////////////////////////////////////////////
   * ********************* Common Product Elements **********************
   * /////////////////////////////////////////////////////
   */
  {
    path: '/tracks/:vertical(skilling|testprep)/',
    component: lazy(() => import('./pages/productElements/tracks/List')),
    exact: true,
    requiredPermission: [PermissionLevels.TRACKS, Permissions.LIST],
  },
  {
    path: `/tracks/:vertical(skilling|testprep)/:trackId(\\d+|new)/:trackMode(${create}|${edit})/`,
    component: lazy(() => import('./pages/productElements/tracks/TrackInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.TRACKS, Permissions.CREATE],
  },
  // Platforms
  {
    path: `/tracks/:vertical(skilling|testprep)/:trackId(\\d+)/platforms/`,
    component: lazy(() => import('./pages/productElements/platform/List')),
    exact: true,
    requiredPermission: [PermissionLevels.PLATFORM, Permissions.LIST],
  },
  {
    path: `/tracks/:vertical(skilling|testprep)/:trackId(\\d+)/platforms/:platformId(\\d+|new)/:platformMode(${create}|${edit})/`,
    component: lazy(() => import('./pages/productElements/platform/PlatformInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.PLATFORM, Permissions.CREATE],
  },
  {
    path: `/tracks/:vertical(skilling|testprep)/:trackId(\\d+)/platforms/:platformId(\\d+)/`,
    component: lazy(() => import('./pages/productElements/platform/PlatformDetails')),
    exact: true,
    requiredPermission: [PermissionLevels.PLATFORM, Permissions.LIST],
  },
  // Screenlets
  {
    path: '/tracks/:vertical(skilling|testprep)/:trackId/screenlets/',
    component: lazy(() => import('./pages/screenlets/ScreenletList')),
    exact: true,
    requiredPermission: [PermissionLevels.SCREENLET, Permissions.LIST],
  },
  {
    path: '/tracks/:vertical(skilling|testprep)/:trackId/screenlets/:screenletId/',
    component: lazy(() => import('./pages/screenlets/ScreenletMappedResources')),
    exact: true,
    requiredPermission: [PermissionLevels.SCREENLET, Permissions.LIST],
  },

  ////////////////////////////////////////////////////////////////////////////////////

  /////////////// Gold Dashboard Clubbing ////////////////////////////////////////////

  ///////////////////////////////////////////////////// ////////////////////////////
  {
    path: '/daily-study-plans/:vertical(skilling|testprep)',
    component: lazy(() => import('./pages/studyplans/premium/listing')),
    exact: true,
    requiredPermission: [PermissionLevels.DAILYSTUDYPLAN, Permissions.LIST],
  },
  {
    path: '/daily-study-plans/:vertical(skilling|testprep)/new',
    component: lazy(() => import('./pages/studyplans/premium/new')),
    exact: true,
    requiredPermission: [PermissionLevels.DAILYSTUDYPLAN, Permissions.CREATE],
  },
  {
    path: '/daily-study-plans/:vertical(skilling|testprep)/:id',
    component: lazy(() => import('./pages/studyplans/premium/details')),
    exact: true,
    requiredPermission: [PermissionLevels.DAILYSTUDYPLAN, Permissions.EDIT],
  },
  {
    path: '/free-study-plans/:vertical(skilling|testprep)',
    component: lazy(() => import('./pages/studyplans/freeTrials/listing')),
    exact: true,
    requiredPermission: [PermissionLevels.FREETRIALSTUDYPLAN, Permissions.LIST],
  },
  {
    path: '/free-study-plans/:vertical(skilling|testprep)/new',
    component: lazy(() => import('./pages/studyplans/freeTrials/new')),
    exact: true,
    requiredPermission: [PermissionLevels.FREETRIALSTUDYPLAN, Permissions.CREATE],
  },
  {
    path: '/free-study-plans/:vertical(skilling|testprep)/:id',
    component: lazy(() => import('./pages/studyplans/freeTrials/details')),
    exact: true,
    requiredPermission: [PermissionLevels.FREETRIALSTUDYPLAN, Permissions.EDIT],
  },
  {
    path: '/onboarding-questions',
    component: lazy(() => import('./pages/goldQuestions/list')),
    exact: true,
    requiredPermission: [PermissionLevels.ONBOARDINGQUESTION, Permissions.LIST],
  },
  {
    path: `/onboarding-questions/:questionId(\\d+|new)/:mode(create|edit)/`,
    component: lazy(() => import('./pages/goldQuestions/QuestionInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.ONBOARDINGQUESTION, Permissions.EDIT],
  },
  {
    path: '/gold-purchases/:vertical(skilling|testprep)',
    component: lazy(() => import('./pages/goldPurchases')),
    exact: true,
    requiredPermission: [PermissionLevels.PURCHASE, Permissions.LIST],
  },
  {
    path: '/gold-Programs/:vertical(skilling|testprep)',
    component: lazy(() => import('./pages/productPackage/list')),
    exact: true,
    requiredPermission: [PermissionLevels.GOLDPROGRAM, Permissions.LIST],
  },
  {
    path: '/gold-Programs/:vertical(skilling|testprep)/:productPackageId(\\d+)/',
    component: lazy(() => import('./pages/productPackage/details')),
    exact: true,
    requiredPermission: [PermissionLevels.GOLDPROGRAM, Permissions.EDIT],
  },
  {
    path: '/gold-Programs/:vertical(skilling|testprep)/:productPackageId(\\d+)/questions/',
    component: lazy(() => import('./pages/productPackage/questions')),
    exact: true,
    requiredPermission: [PermissionLevels.GOLDPROGRAM, Permissions.EDIT],
  },

  ///Clubbing End
  {
    path: '/packs/',
    component: lazy(() => import('./pages/skilling/PackList')),
    exact: true,
    requiredPermission: [PermissionLevels.PACKS, Permissions.LIST],
  },
  {
    path: '/packs/:packId(\\d+)/',
    component: lazy(() => import('./pages/skilling/packDetails')),
    exact: true,
    requiredPermission: [PermissionLevels.PACKS, Permissions.LIST],
  },
  {
    path: `/packs/:packId(\\d+|new)/:mode(${create}|${edit})/`,
    component: lazy(() => import('./pages/skilling/PackInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.PACKS, Permissions.LIST],
  },
  {
    path: '/packs/:packId(\\d+)/quizzes/add/',
    component: lazy(() => import('./pages/exams/quiz/QuizInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.PACKS, Permissions.LIST],
  },
  {
    path: '/packs/:packId(\\d+)/quizzes/:quizId/edit/',
    component: lazy(() => import('./pages/exams/quiz/QuizInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.PACKS, Permissions.LIST],
  },
  {
    path: '/packs/:packId(\\d+)/video/add/',
    component: lazy(() => import('./pages/videos/video/VideoInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.VIDEOS, Permissions.CREATE],
  },
  {
    path: '/packs/:packId(\\d+)/video/:videoId/edit/',
    component: lazy(() => import('./pages/videos/video/VideoInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.VIDEOS, Permissions.EDIT],
  },
  {
    path: '/packs/:packId(\\d+)/video/:videoId/',
    component: lazy(() => import('./pages/videos/video/VideoDetails')),
    exact: true,
    requiredPermission: [PermissionLevels.VIDEOS, Permissions.LIST],
  },
  {
    path: '/packs/:packId(\\d+)/stacks/create/',
    component: lazy(() => import('./pages/studycards/stacks/StackCreate')),
    exact: true,
    requiredPermission: [PermissionLevels.STUDY_CARD_STACKS, Permissions.CREATE],
  },
  {
    path: '/packs/:packId(\\d+)/stacks/:stackId/edit/',
    component: lazy(() => import('./pages/studycards/stacks/StackEdit')),
    exact: true,
    requiredPermission: [PermissionLevels.STUDY_CARD_STACKS, Permissions.EDIT],
  },
  {
    path: '/packs/:packId(\\d+)/stacks/:stackId/cards/',
    component: lazy(() => import('./pages/studycards/cards/CardList')),
    exact: true,
    requiredPermission: [PermissionLevels.STUDY_CARD_CARDS, Permissions.LIST],
  },
  {
    path: '/packs/:packId(\\d+)/stacks/:stackId/cards/create/',
    component: lazy(() => import('./pages/studycards/cards/CardCreate')),
    exact: true,
    requiredPermission: [PermissionLevels.STUDY_CARD_CARDS, Permissions.CREATE],
  },
  {
    path: '/packs/:packId(\\d+)/stacks/:stackId/cards/:cardId/:mode/',
    component: lazy(() => import('./pages/studycards/cards/CardEdit')),
    exact: true,
    requiredPermission: [PermissionLevels.STUDY_CARD_CARDS, Permissions.EDIT],
  },
  // Subjects
  {
    path: `/tracks/:vertical(skilling|testprep)/:trackId(\\d+)/platforms/:platformId(\\d+)/subjects/`,
    component: lazy(() => import('./pages/productElements/subject/List')),
    exact: true,
    requiredPermission: [PermissionLevels.SUBJECT, Permissions.LIST],
  },
  {
    path: `/tracks/:vertical(skilling|testprep)/:trackId(\\d+)/platforms/:platformId(\\d+)/subjects/:subjectId(\\d+|new)/:subjectMode(${create}|${edit})/`,
    component: lazy(() => import('./pages/productElements/subject/SubjectInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.SUBJECT, Permissions.CREATE],
  },
  //Modules
  {
    path: `/tracks/:vertical(skilling|testprep)/:trackId(\\d+)/platforms/:platformId(\\d+)/subjects/:subjectId(\\d+)/`,
    component: lazy(() => import('./pages/productElements/module/List')),
    exact: true,
    requiredPermission: [PermissionLevels.MODULE, Permissions.LIST],
  },
  {
    path: `/tracks/:vertical(skilling|testprep)/:trackId(\\d+)/platforms/:platformId(\\d+)/subjects/:subjectId(\\d+)/modules/:moduleId(\\d+|new)/:moduleMode(${create}|${edit})/`,
    component: lazy(() => import('./pages/productElements/module/ModuleInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.MODULE, Permissions.CREATE],
  },
  //Topics
  {
    path: `/tracks/:vertical(skilling|testprep)/:trackId(\\d+)/platforms/:platformId(\\d+)/subjects/:subjectId(\\d+)/modules/:moduleId(\\d+)/`,
    component: lazy(() => import('./pages/productElements/topic/List')),
    exact: true,
    requiredPermission: [PermissionLevels.TOPIC, Permissions.LIST],
  },
  {
    path: `/tracks/:vertical(skilling|testprep)/:trackId(\\d+)/platforms/:platformId(\\d+)/subjects/:subjectId(\\d+)/modules/:moduleId(\\d+)/topics/:topicId(\\d+|new)/:topicMode(${create}|${edit})/`,
    component: lazy(() => import('./pages/productElements/topic/TopicInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.TOPIC, Permissions.CREATE],
  },

  // Pricing & Coupons - Product packages and plans

  {
    path: '/pricing/productpackages/',
    component: lazy(() => import('./pages/pricingAndCoupons/productPackages/List')),
    exact: true,
    requiredPermission: [PermissionLevels.PRODUCTPACKAGE, Permissions.LIST],
  },

  {
    path: `/pricing/productpackages/:productPackageId(\\d+|new)/:mode(${create}|${edit})/`,
    component: lazy(
      () => import('./pages/pricingAndCoupons/productPackages/ProductPackageInterface'),
    ),
    exact: true,
    requiredPermission: [PermissionLevels.PRODUCTPACKAGE, Permissions.CREATE],
  },

  {
    path: '/pricing/productpackages/:productPackageId(\\d+)/plans',
    component: lazy(() => import('./pages/pricingAndCoupons/plans/List')),
    exact: true,
    requiredPermission: [PermissionLevels.PLAN, Permissions.LIST],
  },
  {
    path: `/pricing/productpackages/:productPackageId(\\d+)/plans/:planId(\\d+|new)/:mode(${create}|${edit})/`,
    component: lazy(() => import('./pages/pricingAndCoupons/plans/PlansInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.PLAN, Permissions.CREATE],
  },

  {
    path: `/pricing/plans/:planId(\\d+|new)/:mode(${create}|${edit})/`,
    component: lazy(() => import('./pages/pricingAndCoupons/plans/PlansInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.PLAN, Permissions.CREATE],
  },

  {
    path: '/pricing/productpackages/:productPackageId(\\d+)/',
    component: lazy(() => import('./pages/pricingAndCoupons/productPackages/ProductPackageReview')),
    exact: true,
    requiredPermission: [PermissionLevels.PRODUCTPACKAGE, Permissions.LIST],
  },

  {
    path: '/pricing/productpackages/:productPackageId(\\d+)/history',
    component: lazy(
      () => import('./pages/pricingAndCoupons/productPackages/ProductPackageHistory'),
    ),
    exact: true,
    requiredPermission: [PermissionLevels.PRODUCTPACKAGE, Permissions.LIST],
  },

  {
    path: '/pricing/productpackages/:productPackageId(\\d+)/plans/:planId(\\d+)/',
    component: lazy(() => import('./pages/pricingAndCoupons/plans/PlansView')),
    exact: true,
    requiredPermission: [PermissionLevels.PLAN, Permissions.LIST],
  },

  {
    path: '/pricing/productpackages/:productPackageId(\\d+)/plans/:planId(\\d+)/history',
    component: lazy(() => import('./pages/pricingAndCoupons/plans/PlansHistory')),
    exact: true,
    requiredPermission: [PermissionLevels.PLAN, Permissions.LIST],
  },
  //Batch Reprot - Gold
  {
    path: '/daily-study-plans/:vertical(skilling|testprep)/:id(\\d+)/batch-report/:batchId(\\d+)',
    component: lazy(() => import('./pages/batchReport/Overall')),
    exact: true,
    requiredPermission: [PermissionLevels.DAILYSTUDYPLAN, Permissions.CREATE],
  },
  {
    path: '/daily-study-plans/:vertical(skilling|testprep)/:id(\\d+)/batch-report/:batchId(\\d+)/student/:studentId(\\d+)',
    component: lazy(() => import('./pages/batchReport/StudentReport')),
    exact: true,
    requiredPermission: [PermissionLevels.DAILYSTUDYPLAN, Permissions.CREATE],
  },
  {
    path: '/daily-study-plans/:vertical(skilling|testprep)/:id(\\d+)/batch-report/:batchId(\\d+)/attendance',
    component: lazy(() => import('./pages/attendance')),
    exact: true,
    requiredPermission: [PermissionLevels.DAILYSTUDYPLAN, Permissions.CREATE],
  },
  // Reported Questions
  {
    path: '/questions/reported/',
    component: lazy(() => import('./pages/reportedQuestions/ReportedQuestions')),
    requiredPermission: [PermissionLevels.REPORTED_QUESTIONS, Permissions.LIST],
    exact: true,
  },
  // Questions Mapping
  {
    path: '/questions/mapping/',
    component: lazy(() => import('./pages/mapping/Platforms')),
    requiredPermission: [PermissionLevels.QUESTIONS_MAPPING, Permissions.LIST],
    exact: true,
  },
  {
    path: '/questions/mapping/:id/',
    component: lazy(() => import('./pages/mapping/Questions')),
    requiredPermission: [PermissionLevels.QUESTIONS_MAPPING, Permissions.LIST],
    exact: true,
  },
  //Live Class
  // {
  //   path: '/daily-study-plans/:vertical(skilling|testprep)/:id(\\d+)/live-class/:liveId(\\d+)',
  //   component: lazy(() => import('./pages/liveClass')),
  //   exact: true,
  //   requiredPermission: [PermissionLevels.DAILYSTUDYPLAN, Permissions.CREATE],
  // },
  // {
  //   path: '/free-study-plans/:vertical(skilling|testprep)/:id(\\d+)/live-class/:liveId(\\d+)',
  //   component: lazy(() => import('./pages/liveClass')),
  //   exact: true,
  //   requiredPermission: [PermissionLevels.DAILYSTUDYPLAN, Permissions.CREATE],
  // },
  {
    path: '/live-classes/:vertical(skilling|testprep)',
    component: lazy(() => import('./pages/live/listing')),
    exact: true,
    requiredPermission: [PermissionLevels.DAILYSTUDYPLAN, Permissions.LIST],
  },
  {
    path: `/live-classes/:vertical(skilling|testprep)/:liveClassId(\\d+|new)/:mode(${create}|${edit})/`,
    component: lazy(() => import('./pages/live/LiveClassInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.DAILYSTUDYPLAN, Permissions.CREATE],
  },
  // {
  //   path: '/live-classes/:vertical(skilling|testprep)/live-class/:liveId(\\d+)',
  //   component: lazy(() => import('./pages/liveClass')),
  //   exact: true,
  //   requiredPermission: [PermissionLevels.DAILYSTUDYPLAN, Permissions.CREATE],
  // },
  //Elevate live classes
  {
    path: '/live-classes/:vertical(elevate)',
    component: lazy(() => import('./pages/live/listing')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE_LIVE_CLASS, Permissions.LIST],
  },
  {
    path: '/elevate/batches',
    component: lazy(() => import('./pages/elevate/batches')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE_LIVE_CLASS, Permissions.LIST],
  },
  //Elevate Courses
  {
    path: '/elevate/courses/',
    component: lazy(() => import('./pages/elevate/courses/listing')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE, Permissions.LIST],
  },
  {
    path: '/elevate/courses/create',
    component: lazy(() => import('./pages/elevate/courses/create')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE, Permissions.LIST],
  },
  {
    path: '/elevate/courses/:packId(\\d+)',
    component: lazy(() => import('./pages/elevate/courses/details')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE, Permissions.LIST],
  },
  {
    path: '/elevate/courses/:packId(\\d+)/edit',
    component: lazy(() => import('./pages/elevate/courses/create')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE, Permissions.LIST],
  },

  {
    path: '/elevate/courses/:packId(\\d+)/video/add/',
    component: lazy(() => import('./pages/videos/video/VideoInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE, Permissions.CREATE],
  },
  {
    path: '/elevate/courses/:packId(\\d+)/video/:videoId/edit/',
    component: lazy(() => import('./pages/videos/video/VideoInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE, Permissions.EDIT],
  },
  {
    path: '/elevate/courses/:packId(\\d+)/video/:videoId/',
    component: lazy(() => import('./pages/videos/video/VideoDetails')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE, Permissions.LIST],
  },
  {
    path: '/elevate/courses/:packId(\\d+)/stacks/create/',
    component: lazy(() => import('./pages/studycards/stacks/StackCreate')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE, Permissions.CREATE],
  },
  {
    path: '/elevate/courses/:packId(\\d+)/stacks/:stackId/edit/',
    component: lazy(() => import('./pages/studycards/stacks/StackEdit')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE, Permissions.EDIT],
  },
  {
    path: '/elevate/courses/:packId(\\d+)/stacks/:stackId/cards/',
    component: lazy(() => import('./pages/studycards/cards/CardList')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE, Permissions.LIST],
  },
  {
    path: '/elevate/courses/:packId(\\d+)/stacks/:stackId/cards/create/',
    component: lazy(() => import('./pages/studycards/cards/CardCreate')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE, Permissions.CREATE],
  },
  {
    path: '/elevate/courses/:packId(\\d+)/stacks/:stackId/cards/:cardId/:mode/',
    component: lazy(() => import('./pages/studycards/cards/CardEdit')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE, Permissions.EDIT],
  },
  {
    path: '/elevate/courses/:packId/quizzes/add/',
    component: lazy(() => import('./pages/exams/quiz/QuizInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE, Permissions.LIST],
  },
  {
    path: '/elevate/courses/:packId/quizzes/:quizId/edit/',
    component: lazy(() => import('./pages/exams/quiz/QuizInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE, Permissions.LIST],
  },

  //New Elevate Batch Report
  {
    path: '/elevate-v1/pack/:packId/batches/:batchId/:task?',
    component: lazy(() => import('./pages/elevate/newElevateBatch')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE, Permissions.LIST],
  },

  //Elevate Courses
  {
    path: '/elevate/attendance/:batchId',
    component: lazy(() => import('./pages/attendance')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE_LIVE_CLASS, Permissions.LIST],
  },
  {
    path: '/elevate/pack/:packId/batches/:batchId/:task?',
    component: lazy(() => import('./pages/elevate/batch')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE_LIVE_CLASS, Permissions.LIST],
  },
  // New elevate student report
  {
    path: '/elevate-v1/pack/:packId/batches/:batchId/students/:studentId',
    component: lazy(() => import('./pages/elevate/studentReport')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE_LIVE_CLASS, Permissions.LIST],
  },
  // Old elevate student report
  {
    path: '/elevate/pack/:packId/batches/:batchId/students/:studentId',
    component: lazy(() => import('./pages/elevate/studentReport/OldStudentReport')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE_LIVE_CLASS, Permissions.LIST],
  },
  {
    path: '/elevate/pack/:packId/batches/:batchId/:task/:id',
    component: lazy(() => import('./pages/elevate/studytasks/Task')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE_LIVE_CLASS, Permissions.LIST],
  },
  {
    path: '/elevate-v1/pack/:packId/batches/:batchId/:task/:id',
    component: lazy(() => import('./pages/elevate/studytasks/Task')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE_LIVE_CLASS, Permissions.LIST],
  },
  {
    path: `/live-classes/:vertical(elevate)/:liveClassId(\\d+|new)/:mode(${create}|${edit})/`,
    component: lazy(() => import('./pages/live/LiveClassInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE_LIVE_CLASS, Permissions.CREATE],
  },
  //Elevate Purchases
  {
    path: '/elevate/purchses',
    component: lazy(() => import('./pages/elevatePurchases')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE_PURCHASE, Permissions.LIST],
  },
  // {
  //   path: '/live-classes/:vertical(elevate)/live-class/:liveId(\\d+)',
  //   component: lazy(() => import('./pages/liveClass')),
  //   exact: true,
  //   requiredPermission: [PermissionLevels.ELEVATE_LIVE_CLASS, Permissions.CREATE],
  // },
  // Freelance Profile
  {
    path: `/profiles/`,
    component: lazy(() => import('./pages/profile/ProfileList')),
    exact: true,
    requiredPermission: [PermissionLevels.PROFILE, Permissions.LIST],
  },
  {
    path: `/profiles/:profileId(\\d+|new)/`,
    component: lazy(() => import('./pages/profile/ProfileView')),
    exact: true,
    requiredPermission: [PermissionLevels.PROFILE, Permissions.LIST],
  },
  {
    path: `/profiles/:profileId(\\d+|new)/:mode(${create}|${edit})/`,
    component: lazy(() => import('./pages/profile/ProfileInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.PROFILE, Permissions.CREATE],
  },

  {
    path: `/profiles/:profileId(\\d+|new)/:mode(${create}|${edit})/`,
    component: lazy(() => import('./pages/profile/ProfileInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.PROFILE, Permissions.CREATE],
  },
  // Certificates Templates
  {
    path: '/certificates/createTemplate/',
    component: lazy(() => import('./pages/certificate/createTemplate/TemplateGeneratePageList')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE, Permissions.CREATE],
  },
  {
    path: '/certificates/createTemplate/create',
    component: lazy(() => import('./pages/certificate/createTemplate/GenerateTemplatePageInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE, Permissions.CREATE]
  },
  {
    path: '/certificates/generateCertificate/',
    component: lazy(() => import('./pages/certificate/generateCertificate/GeneratedCertificateList')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE, Permissions.CREATE],
  },
  {
    path: '/certificates/generateCertificate/:mode(single|bulk)/generate',
    component: lazy(() => import('./pages/certificate/generateCertificate/GenerateCertificateInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE, Permissions.CREATE],
  },

  {
    path: '/certificates/user/:id',
    component: lazy(() => import('./pages/certificate/generateCertificate/CertificateDetails')),
    exact: true,
    requiredPermission: [PermissionLevels.ELEVATE, Permissions.CREATE],
  },
  // Landing Page CMS
  {
    path: '/cms/',
    component: lazy(() => import('./pages/landingPageCMS/LandingPageList')),
    exact: true,
    requiredPermission: [PermissionLevels.LANDING_PAGE, Permissions.LIST],
  },
  {
    path: '/cms/templates/',
    component: lazy(() => import('./pages/landingPageCMS/TemplateSelectPage')),
    exact: true,
    requiredPermission: [PermissionLevels.LANDING_PAGE, Permissions.CREATE],
  },
  {
    path: `/cms/templates/:templateSlug/pages/:landingPageId(\\d+|new)/:formMode(${create}|${edit})/`,
    component: lazy(() => import('./pages/landingPageCMS/LandingPageCMSInterface')),
    exact: true,
    requiredPermission: [PermissionLevels.LANDING_PAGE, Permissions.CREATE],
  },
  // Landing Page - Global Components - Categories
  {
    path: '/categories/',
    component: lazy(() => import('./pages/landingPageGlobalComponents/Categories')),
    exact: true,
    requiredPermission: [PermissionLevels.LANDING_PAGE, Permissions.LIST],
  },
  // Landing Page - Global Components - Create Category
  {
    path: '/categories/create/',
    component: lazy(() => import('./pages/landingPageGlobalComponents/CreateCategory')),
    exact: true,
    requiredPermission: [PermissionLevels.LANDING_PAGE, Permissions.ACTIVATE],
  },
  // Landing Page - Global Components
  {
    path: '/categories/:id/global-components/',
    component: lazy(() => import('./pages/landingPageGlobalComponents/GlobalComponents')),
    exact: true,
    requiredPermission: [PermissionLevels.LANDING_PAGE, Permissions.LIST],
  },
  // Fluency Test
  {
    path: '/fluency-test/:userId(\\d+)',
    component: lazy(() => import('./pages/englishFluencyAnalyzer/details')),
    exact: true,
    requiredPermission: [PermissionLevels.DAILYSTUDYPLAN, Permissions.LIST],
  },
  // Remuneration
  {
    path: '/remunerations/live-classes/',
    component: lazy(() => import('./pages/remuneration')),
    exact: true,
    requiredPermission: [PermissionLevels.LIVECLASS_REMUNERATION, Permissions.LIST],
  },
  {
    path: '/validity-extension/',
    component: lazy(() => import('./pages/validityExtension')),
    exact: true,
    requiredPermission: [PermissionLevels.VALIDITY_EXTENSION, Permissions.CREATE],
  },
  // Batches
  {
    path: '/batches/',
    component: lazy(() => import('./pages/batches')),
    exact: true,
    requiredPermission: [PermissionLevels.BATCH, Permissions.LIST],
  },
  {
    path: `/batches/:id/`,
    component: lazy(() => import('./pages/batches/pages/BatchDetails')),
    exact: true,
    requiredPermission: [PermissionLevels.BATCH, Permissions.LIST],
  },
  {
    path: `/batches/:id/edit/`,
    component: lazy(() => import('./pages/batches')),
    exact: true,
    requiredPermission: [PermissionLevels.BATCH, Permissions.LIST],
  },
]

const router: React.FC<RouterProps> = ({ history }) => (
  <Suspense fallback={<Loader spinning />}>
    <ConnectedRouter history={history}>
      <IndexLayout>
        <Switch>
          {routes.map(route =>
            route.requiredPermission ? (
              <AuthRoute
                path={route.path}
                Component={route.component}
                key={route.path}
                exact={route.exact}
                requiredPermission={route.requiredPermission}
              />
            ) : (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ),
          )}
          <Route path={'/403'} component={UnAuthorizedPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </IndexLayout>
    </ConnectedRouter>
  </Suspense>
)

export default router
