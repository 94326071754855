import { FormDimensions } from './types'

export const BATCH_ARCHIVED_UNARCHIVED = [
  {
    id: 'true',
    name: 'Archived Batches',
  },
  {
    id: 'false',
    name: 'Unarchived Batches',
  },
]
export const BATCH_CLOSED_UNCLOSED = [
  {
    id: 'true',
    name: 'Completed Batches',
  },
  {
    id: 'false',
    name: 'Incomplete Batches',
  },
]

export const FORM_DIMENSIONS: FormDimensions = {
  ROW_GUTTER: [24, 16],
  COL_SPAN: 12,
}

export const ACTIONS = [
  {
    id: 'ARCHIVE',
    name: 'Archive Batch',
  },
  {
    id: 'EDIT',
    name: 'Edit Batch',
  },
  {
    id: 'CLOSE',
    name: 'Mark As Completed',
  }
]

export const TOKEN_SEPARATORS = [' ', ',']

export const TEL_REGEX = /^\+?\d{1,3}[- ]?\d{3,}$/

export enum ADD_STUDENTS_WARNING_NOTES {
  NEED_ENTRI_APP_ACCOUNT = 'The phone number should be associated with an Entri App account.',
  NUMBERS_SHOULD_HAVE_COUNTRY_CODE = 'Phone numbers should commence with the country code (e.g., +91, +1, ...).',
  HOW_TO_ADD_MULTIPLE_PHONE_NUMBERS = 'You can add multiple phone numbers by separating them with a comma ( , ) or a space ( _ ) after each phone number.',
}

export enum PERMISSION_HELPER_TEXT {
  NO_EDIT = 'You do not have the necessary permissions to edit this item.'
}

export const MENTOR_ROLES = [
  'freelance-gold-mentor',
  'gold-mentor-lead',
  'asst.-gold-mentor',
  'freelance-asst.-gold-mentor',
  'gold-mentor',
  'elevate-mentor',
]

export const COURSE_VERTICALS = [
  {
    value: 'TESTPREP',
    label: 'Testprep',
  },
  {
    value: 'SKILLING',
    label: 'Skilling',
  },
  {
    value: 'ELEVATE',
    label: 'Elevate',
  },
]
